import 'tailwindcss/tailwind.css';
import Head from 'next/head';
import React, { useEffect, useState, useRef } from 'react';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { v4 as uuidv4 } from 'uuid';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import '../styles/styles.css';
import { IBrowserSettings } from '@splitsoftware/splitio-react/types/splitio/splitio';

function MyApp({ Component, pageProps }: AppProps): JSX.Element | null {
  const [splitConfigReady, setSplitConfigReady] = useState(false);
  const [splitConfig, setSplitConfig] = useState<IBrowserSettings | null>(null);

  const key = useRef<string | null>();

  const router = useRouter();

  useEffect(() => {
    if (router.isReady && !splitConfigReady) {
      key.current = window.localStorage.getItem('splitKey');

      if (!key.current) {
        key.current = uuidv4();
        window.localStorage.setItem('splitKey', key.current);
      }

      setSplitConfig({
        core: {
          authorizationKey: process.env.NEXT_PUBLIC_SPLIT_API_KEY ?? '',
          key: key.current,
          trafficType: 'anonymous',
        },
        scheduler: {
          eventsPushRate: 1,
        },
      });
      setSplitConfigReady(true);
    }
  }, [key, router.isReady, splitConfigReady]);

  if (!splitConfigReady) {
    return null;
  }

  return (
    <>
      <Head>
        <title>Froedtert & MCW Portal</title>
      </Head>
      <SplitFactory config={splitConfig}>
        <Component {...pageProps} />
      </SplitFactory>
    </>
  );
}
export default MyApp;
